// Customizable Area Start
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";

import { RouteComponentProps } from "react-router";

export const configJSON = require("./config");

export interface Props extends RouteComponentProps {
  navigation: any;
  id: string;
}

export interface S {
  showPassword: boolean;
}

export interface SS {
  id: any;
}

export default class OrganizationUserSignUpController extends BlockComponent<
  Props,
  S,
  SS
> {

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];
    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      showPassword: false,
    };
  }
  async componentDidMount() {
    let temp = this.props.location
    console.log(temp)
    super.componentDidMount();
  }

  userSignUpApiCallID: string = "";

  apiCall = async (data: any) => {
    const token = localStorage.getItem("accesToken") as string;
    const { contentType, method, endPoint, body, type } = data;
    const header = {
      "Content-Type": contentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),

      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body && type != "formData"
      ? requestMessage.addData(
          getName(MessageEnum.RestAPIRequestBodyMessage),
          JSON.stringify(body)
        )
      : requestMessage.addData(
          getName(MessageEnum.RestAPIRequestBodyMessage),
          body
        );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  handleSignUp = (values: any) => {
    let temp = (this.props.location.pathname).split("token=")
    let token =  temp[1] ? temp[1] : "3a739b04bab9c7c24686"
    let bodyData = {
      data: {
        type: "email_account",
        attributes: {
          email: values?.email,
          password: values?.password,
          designation: values?.designation,
          department: values?.department,
          full_name: values.name,
          full_phone_number: values?.phone,
        },
      },
    };

    this.UserSignUp(bodyData,token);
  };

  UserSignUp = async (bodyData: object,token: string) => {
    this.userSignUpApiCallID = await this.apiCall({
      contentType: "application/json",
      method: "POST",
      endPoint: `/account_block/accounts?unique_token=${token}`,
      body:bodyData
    });
  };
  handleClickShowPassword = () => {
    this.setState({ showPassword: !this.state.showPassword });
  };
  setPhoneVal= (formik:any,number:string)=>{
    formik.setFieldValue("phone", number)
  }
}
// Customizable Area End
