import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start
  navigation: any;
  id: string;
  // Customizable Area End
}

export interface S {
  // Customizable Area Start
  stepLabels: Array<string>;
  prevActiveStep: number;
  activeStep: number;
  showPassword: boolean;
  UploadFiles: Array<File>;
  FileName: string;
  BusinessRegistrationDocument: Array<File>;
  BRDFileName: string;
  AuthLetterByPOrg: Array<File>;
  AuthLetterFileName: string;
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class OrganizationSignUpController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];
    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      // Customizable Area Start
      stepLabels: [],
      prevActiveStep: 0,
      activeStep: 0,
      showPassword: false,
      UploadFiles: [],
      FileName: "",
      BusinessRegistrationDocument: [],
      BRDFileName: "",
      AuthLetterByPOrg: [],
      AuthLetterFileName: "",
      // Customizable Area End
    };
  }
  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    let temp = [
      "Organization Details",
      "Principal Organization Details",
      "Admin’s Profile",
      "Review &Submit",
    ];
    this.setState({
      stepLabels: temp,
    });
  }

  handleNext = () => {
    this.setState({ activeStep: this.state.activeStep + 1 });
  };

  handleBack = () => {
    this.setState({ activeStep: this.state.activeStep - 1 });
  };
  handleClickShowPassword = () => {
    this.setState({ showPassword: !this.state.showPassword });
  };

  UploadFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = e.target;
    const selectedFiles = files as FileList;
    if (selectedFiles[0].type === "application/pdf") {
      this.setState({
        UploadFiles: this.state.UploadFiles.concat(selectedFiles[0]),
        FileName: selectedFiles[0].name,
      });
    } else {
      alert(`cant upload ${selectedFiles[0].type.split("/")[0]}`);
    }
  };
  UploadFile2 = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = e.target;
    const selectedFiles = files as FileList;
    if (selectedFiles[0].type === "application/pdf") {
      this.setState({
        BusinessRegistrationDocument: this.state.BusinessRegistrationDocument.concat(selectedFiles[0]),
        BRDFileName: selectedFiles[0].name,
      });
    } else {
      alert(`cant upload ${selectedFiles[0].type.split("/")[0]}`);
    }
  };
  UploadFile3 = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = e.target;
    const selectedFiles = files as FileList;
    if (selectedFiles[0].type === "application/pdf") {
      this.setState({
        AuthLetterByPOrg: this.state.AuthLetterByPOrg.concat(selectedFiles[0]),
        AuthLetterFileName: selectedFiles[0].name,
      });
    } else {
      alert(`cant upload ${selectedFiles[0].type.split("/")[0]}`);
    }
  };
  // Customizable Area End
}
