// Customizable Area Start
import React from "react";
import Button from "@material-ui/core/Button";
import { Formik, ErrorMessage } from "formik";
import AppBackground from "../../../components/src/AppBackground.web";
import GenericCard from "../../../components/src/GenericCard.web";
import OrganizationUserSignUpController from "./OrganizationUserSignUpController.web";
import { Box, InputLabel } from "@material-ui/core";
import { styled } from "@material-ui/styles";
import Grid from "@material-ui/core/Grid";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import PhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/style.css';
import { withRouter } from "react-router-dom";
import * as Yup from "yup";

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Full Name is required"),
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  designation: Yup.string().required("Designation is required"),
  department: Yup.string().required("Department is required"),
  phone: Yup.string().required("Phone Number is required"),
  password: Yup.string()
    .matches(/^(?=.*[A-Z])/, "Must have at least one uppercase letter")
    .matches(
      /^(?=.*[!@#$&*?<>',\[\]}{=\-)(^%`~+. :;_])/,
      "Must have at least one special case symbol"
    )
    .matches(/\d/, "Must have at least one digit")
    .matches(/^(?=.*[a-z])/, "Must have at least one lowercase letter")
    .matches(/.{8,}/, "Must be at least 8 characters long")
    .required("Password is required"),
});

class OrganizationUserSignUp extends OrganizationUserSignUpController {
  constructor(props: any) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    return (
      // Required for all blocks
      <UserPageStyle>
        <AppBackground>
          <GenericCard>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <div className="title" data-test-id="OrganizationUserSignUp">
                  <h2>Create your SlotXpert Account with</h2>
                  <h3>AP Moller Maersk Line Shipping Co.</h3>
                </div>
              </Grid>
              <div>
                <Formik
                  initialValues={{
                    name: "",
                    email: "",
                    designation: "",
                    department: "",
                    phone: "",
                    password: "",
                  }}
                  validationSchema={validationSchema}
                  onSubmit={(values) => {
                    this.handleSignUp(values);
                  }}
                >
                  {(formik) => (
                    <form onSubmit={formik.handleSubmit}>
                      <>
                        <Grid container spacing={2}>
                          <Grid item xs={6}>
                            <InputContainer>
                              <div>
                                <CustmizedInputLabel>
                                  FULL NAME
                                </CustmizedInputLabel>
                              </div>
                              <div className="Custmizedinputs">
                                <input
                                  className="textbox"
                                  data-test-id="name"
                                  name="name"
                                  type="text"
                                  onChange={formik.handleChange}
                                  value={formik.values.name}
                                />
                                <ErrorMessage
                                  name="name"
                                  component="div"
                                  className="error"
                                />
                              </div>
                            </InputContainer>
                          </Grid>
                          <Grid item xs={6}>
                            <InputContainer>
                              <div>
                                <CustmizedInputLabel>
                                  EMAIL ADDRESS
                                </CustmizedInputLabel>
                              </div>
                              <div className="Custmizedinputs">
                                <input
                                  className="textbox"
                                  data-test-id="email"
                                  name="email"
                                  type="email"
                                  onChange={formik.handleChange}
                                  value={formik.values.email}
                                />
                                <ErrorMessage
                                  name="email"
                                  component="div"
                                  className="error"
                                />
                              </div>
                            </InputContainer>
                          </Grid>
                          <Grid item xs={6}>
                            <InputContainer>
                              <div>
                                <CustmizedInputLabel>
                                  DESIGNATION
                                </CustmizedInputLabel>
                              </div>
                              <div className="Custmizedinputs">
                                <input
                                  className="textbox"
                                  name="designation"
                                  type="text"
                                  data-test-id="designation"
                                  onChange={formik.handleChange}
                                  value={formik.values.designation}
                                />
                                <ErrorMessage
                                  name="designation"
                                  component="div"
                                  className="error"
                                />
                              </div>
                            </InputContainer>
                          </Grid>
                          <Grid item xs={6}>
                            <InputContainer>
                              <div>
                                <CustmizedInputLabel>
                                  DEPARTMENT
                                </CustmizedInputLabel>
                              </div>
                              <div className="Custmizedinputs">
                                <input
                                  className="textbox"
                                  name="department"
                                  data-test-id="department"
                                  type="text"
                                  onChange={formik.handleChange}
                                  value={formik.values.department}
                                />
                                <ErrorMessage
                                  name="department"
                                  component="div"
                                  className="error"
                                />
                              </div>
                            </InputContainer>
                          </Grid>
                          <Grid item xs={6}>
                            <InputContainer>
                              <div>
                                <CustmizedInputLabel>
                                  CONTACT NUMBER
                                </CustmizedInputLabel>
                              </div>
                              <div className="Custmizedinputs">
                                <PhoneInput
                                  country={"us"}
                                  value={formik.values.phone}
                                  onChange={(number: string) => this.setPhoneVal(formik,number)}
                                  inputProps={{
                                    required: true,
                                    id: "phone",
                                    name: "phone",
                                    "data-test-id" : "PhoneNumber"
                                  }}
                                  containerStyle={styles.container}
                                  buttonStyle={styles.button}
                                  inputStyle={styles.input}
                                />
                                <ErrorMessage
                                  name="phone"
                                  component="div"
                                  className="error"
                                />
                              </div>
                            </InputContainer>
                          </Grid>
                          <Grid item xs={6}>
                            <InputContainer>
                              <div>
                                <CustmizedInputLabel>
                                  PASSWORD
                                </CustmizedInputLabel>
                              </div>
                              <div className="Custmizedinputs">
                                <CustmizedPassword
                                  id="standard-adornment-password"
                                  name="password"
                                  onChange={formik.handleChange}
                                  value={formik.values.password}
                                  type={
                                    this.state.showPassword
                                      ? "text"
                                      : "password"
                                  }
                                  inputProps={{ "data-test-id": "password" }}
                                  endAdornment={
                                    <InputAdornment position="end">
                                      <IconButton
                                        data-test-id="showPassword"
                                        aria-label="toggle password visibility"
                                        onClick={this.handleClickShowPassword}
                                      >
                                        {this.state.showPassword ? (
                                          <VisibilityOff />
                                        ) : (
                                          <Visibility />
                                        )}
                                      </IconButton>
                                    </InputAdornment>
                                  }
                                />
                                <ErrorMessage
                                  name="password"
                                  component="div"
                                  className="error"
                                />
                              </div>
                            </InputContainer>
                          </Grid>
                          <Grid item xs={12} className="buttonContainer">
                            <Button
                              type="submit"
                              variant="contained"
                              className="customizedButton"
                              data-test-id="submitButton"
                            >
                              CREATE ACCOUNT
                            </Button>
                          </Grid>
                        </Grid>
                      </>
                    </form>
                  )}
                </Formik>
              </div>
            </Grid>
          </GenericCard>
        </AppBackground>
      </UserPageStyle>
    );
  }
}
export default withRouter(OrganizationUserSignUp);

const styles = {
  container: {
    width: "100%",
    height: "56px",
    borderRadius: "8px",
    border: "1px solid gray",
  },
  button: {
    border: "none",
    borderRadius: "9px",
  },
  input: {
    height: "56px",
    width: "100%",
    border: "none",
    borderRadius: "9px",
  },
};
const InputContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
});
const CustmizedInputLabel = styled(InputLabel)({
  fontSize: "14px",
  lineHeight: "16px",
  letterSpacing: "0em",
  textAlign: "left",
  fontFamily: "Asap",
  display: "contents",
  color: "black !important",
  textTransform: "uppercase",
});
const CustmizedPassword = styled(Input)({
  width: "100%",
  height: "56px",
  padding: "10px 22px 10px 12px",
  borderRadius: "8px",
  border: "1px solid gray",
  outline: "none",
});
const UserPageStyle = styled("div")({
  "& .title": {
    textAlign: "center",
  },
  "& .error": {
    color: "red",
    marginTop: "0.2rem",
    fontSIze:"10px"
  },
  "& .buttonContainer": {
    display: "flex",
    justifyContent: "center",
    alignItems: "cenetr",
  },
  "& .customizedButton": {
    padding: "16px, 58px, 16px, 58px",
    borderRadius: "45px",
    backgroundColor: "#1653DD",
    color: "white",
  },
  "& .phone": {
    width: "100%",
    height: "56px",
    padding: "10px 22px 10px 12px",
    borderRadius: "5px",
    fontSize: "16px",
    paddingLeft: "35px",
    fontWeight: 700,
  },
  "& .Custmizedinputs": {
    width: "100% !important",
    height: "56px",
    padding: "10px 0px 10px 0px",
    borderRadius: "8px",
    backgroundcolor: "red",
    borderRadious: "8px",
  },
  "& .textbox": {
    width: "100%",
    height: "56px",
    padding: "10px 22px 10px 12px",
    borderRadius: "8px",
    border: "1px solid gray",
  },
  "& .infoIcon": {
    fontSize: "1.2rem",
    marginLeft: "0.6rem",
  },
  "& .react-tel-input .flag-dropdown.open .selected-flag": {
    background: "#ffffff00",
    borderRadius: "3px 0 0 0",
  },
  "& .react-tel-input .selected-flag:hover, .react-tel-input .selected-flag:focus": {
    backgroundColor: "#fff0",
  },
});
// Customizable Area End
