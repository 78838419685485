// Customizable Area Start
import React from "react";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Button from "@material-ui/core/Button";
import { Formik } from "formik";
import AppBackground from "../../../components/src/AppBackground.web";
import GenericCard from "../../../components/src/GenericCard.web";
import OrganizationSignUpController from "./OrganizationSignUpController.web";
import { styled } from "@material-ui/styles";
import Grid from "@material-ui/core/Grid";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import InputAdornment from "@material-ui/core/InputAdornment";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import Input from "@material-ui/core/Input";
import {
  Box,
  InputLabel,
  RadioGroup,
  Tooltip,
  Radio,
  FormControlLabel,
  TextField,
  Typography,
} from "@material-ui/core";

export default class OrganizationSignUp extends OrganizationSignUpController {
  constructor(props: any) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  renderBlock = (step: number, formik: any) => {
    const Block1 = (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <div className="title">Organization Details</div>
        </Grid>
        <Grid item xs={6}>
          <InputContainer>
            <div className="setDisplay">
              <CustmizedInputLabel>
                ORGANISATION'S FULL NAME
              </CustmizedInputLabel>
              <Tooltip
                title="Full legal name of your organisation as registered with the local authority."
                placement="right"
                arrow
                className="tooltip"
              >
                <InfoOutlinedIcon className="infoIcon" />
              </Tooltip>
            </div>
            <div className="Custmizedinputs">
              <input
                className="textbox"
                name="OrganizationName"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.OrganizationName}
              />
            </div>
          </InputContainer>
        </Grid>
        <Grid item xs={6}>
          <InputContainer>
            <div className="setDisplay">
              <CustmizedInputLabel>ORGANISATION'S UEN</CustmizedInputLabel>
              <Tooltip
                title="The unique registration number assigned to your organization by the local authority."
                placement="right"
                arrow
                className="tooltip"
              >
                <InfoOutlinedIcon className="infoIcon" />
              </Tooltip>
            </div>
            <div className="Custmizedinputs">
              <input
                className="textbox"
                name="OrganizationUEN"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.OrganizationUEN}
              />
            </div>
          </InputContainer>
        </Grid>
        <Grid item xs={6}>
          <InputContainer>
            <div className="setDisplay">
              <CustmizedInputLabel>
                Business Registration Document
              </CustmizedInputLabel>
              <Tooltip
                title="A legal document confirming your business's existence and official status."
                placement="right"
                arrow
                className="tooltip"
              >
                <InfoOutlinedIcon className="infoIcon" />
              </Tooltip>
            </div>
            <div className="Custmizedinputs">
              {this.state.UploadFiles.length === 0 ? (
                <label htmlFor="pdf">
                  <Button
                    className=""
                    fullWidth
                    id="PdfFile"
                    component="span"
                    variant="outlined"
                    style={{
                      padding: "15px",
                    }}
                    startIcon={
                      this.state.UploadFiles.length === 0 ? (
                        <CloudUploadIcon />
                      ) : (
                        <InfoOutlinedIcon />
                      )
                    }
                    {...(this.state.UploadFiles.length !== 0
                      ? {
                          endIcon: (
                            <DeleteIcon
                              onClick={() => {
                                this.setState({
                                  UploadFiles: [],
                                });
                              }}
                              style={{ color: "red", zIndex: 9 }}
                            />
                          ),
                        }
                      : {})}
                  >
                    <Typography
                      variant="subtitle2"
                      style={{
                        fontSize: "12px",
                      }}
                    >
                      Browse or drag & drop file here (PDF, DOCX)
                    </Typography>
                  </Button>
                </label>
              ) : (
                <Button
                  className=""
                  fullWidth
                  id="PdfFile"
                  component="span"
                  variant="outlined"
                  style={{
                    padding: "15px",
                  }}
                  startIcon={
                    this.state.UploadFiles.length === 0 ? (
                      <CloudUploadIcon />
                    ) : (
                      <InfoOutlinedIcon />
                    )
                  }
                  {...(this.state.UploadFiles.length !== 0
                    ? {
                        endIcon: (
                          <DeleteIcon
                            onClick={() => {
                              this.setState({
                                UploadFiles: [],
                              });
                            }}
                            style={{ color: "red", zIndex: 9 }}
                          />
                        ),
                      }
                    : {})}
                >
                  <Typography
                    variant="subtitle2"
                    style={{
                      fontSize: "12px",
                    }}
                    noWrap
                  >
                    {this.state.FileName ? this.state.FileName : "" }
                  </Typography>
                </Button>
              )}
              <input
                className="textbox"
                name="pdf"
                id="pdf"
                multiple
                disabled={this.state.UploadFiles?.length !== 0}
                accept="application/pdf"
                type="file"
                style={{ display: "none" }}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  this.UploadFile(e);
                }}
                value={formik.values.OrganizationName}
              />
            </div>
          </InputContainer>
        </Grid>
        <Grid item xs={6}>
          <InputContainer>
            <div className="setDisplay">
              <CustmizedInputLabel>
                ACTING ON BEHALF OF A PRINCIPAL ORGANISATION?
              </CustmizedInputLabel>
            </div>
            <div className="Custmizedinputs">
              <Button
                variant="outlined"
                fullWidth
                style={{
                  padding: "5px 15px",
                  display: "unset",
                  marginTop: "2px",
                }}
              >
                <RadioGroup
                  style={{
                    flexDirection: "row",
                  }}
                >
                  <FormControlLabel
                    value="yes"
                    control={<Radio />}
                    label="Yes"
                  />
                  <FormControlLabel
                    style={{
                      marginLeft: "20px",
                    }}
                    value="no"
                    control={<Radio />}
                    label="No"
                  />
                </RadioGroup>
              </Button>
            </div>
          </InputContainer>
        </Grid>
      </Grid>
    );
    const Block2 = (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <div className="title">Organization Details</div>
        </Grid>
        <Grid item xs={12}>
          <InputContainer>
            <div className="setDisplay">
              <CustmizedInputLabel>
                NAME OF PRINCIPAL ORGANISATION
              </CustmizedInputLabel>
            </div>
            <div className="Custmizedinputs">
              <input
                className="textbox"
                name="PeincipalOrganization"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.PeincipalOrganization}
              />
            </div>
          </InputContainer>
        </Grid>
        <Grid item xs={6}>
          <InputContainer>
            <div className="setDisplay">
              <CustmizedInputLabel>
                Business Registration Document
              </CustmizedInputLabel>
            </div>
            <div className="Custmizedinputs">
              {this.state.BusinessRegistrationDocument.length === 0 ? (
                <label htmlFor="pdf">
                  <Button
                    className=""
                    fullWidth
                    id="PdfFile"
                    component="span"
                    variant="outlined"
                    style={{
                      padding: "15px",
                    }}
                    startIcon={
                      this.state.BusinessRegistrationDocument.length === 0 ? (
                        <CloudUploadIcon />
                      ) : (
                        <InfoOutlinedIcon />
                      )
                    }
                    {...(this.state.BusinessRegistrationDocument.length !== 0
                      ? {
                          endIcon: (
                            <DeleteIcon
                              onClick={() => {
                                this.setState({
                                  BusinessRegistrationDocument: [],
                                });
                              }}
                              style={{ color: "red", zIndex: 9 }}
                            />
                          ),
                        }
                      : {})}
                  >
                    <Typography
                      variant="subtitle2"
                      style={{
                        fontSize: "12px",
                      }}
                    >
                      Browse or drag & drop file here (PDF, DOCX)
                    </Typography>
                  </Button>
                </label>
              ) : (
                <Button
                  className=""
                  fullWidth
                  id="PdfFile"
                  component="span"
                  variant="outlined"
                  style={{
                    padding: "15px",
                  }}
                  startIcon={
                    this.state.BusinessRegistrationDocument.length === 0 ? (
                      <CloudUploadIcon />
                    ) : (
                      <InfoOutlinedIcon />
                    )
                  }
                  {...(this.state.BusinessRegistrationDocument.length !== 0
                    ? {
                        endIcon: (
                          <DeleteIcon
                            onClick={() => {
                              this.setState({
                                BusinessRegistrationDocument: [],
                              });
                            }}
                            style={{ color: "red", zIndex: 9 }}
                          />
                        ),
                      }
                    : {})}
                >
                  <Typography
                    variant="subtitle2"
                    style={{
                      fontSize: "12px",
                    }}
                    noWrap
                  >
                    {this.state.BRDFileName}
                  </Typography>
                </Button>
              )}
              <input
                className="textbox"
                name="pdf"
                id="pdf"
                multiple
                disabled={this.state.BusinessRegistrationDocument.length !== 0}
                accept="application/pdf"
                type="file"
                style={{ display: "none" }}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  this.UploadFile2(e);
                }}
                value={formik.values.OrganizationName}
              />
            </div>
          </InputContainer>
        </Grid>
        <Grid item xs={6}>
          <InputContainer>
            <div className="setDisplay">
              <CustmizedInputLabel>
              AUTHORIsATION LETTER BY PRINCIPAL ORGANIsATION
              </CustmizedInputLabel>
            </div>
            <div className="Custmizedinputs">
              {this.state.AuthLetterByPOrg.length === 0 ? (
                <label htmlFor="pdf">
                  <Button
                    className=""
                    fullWidth
                    id="PdfFile"
                    component="span"
                    variant="outlined"
                    style={{
                      padding: "15px",
                    }}
                    startIcon={
                      this.state.AuthLetterByPOrg.length === 0 ? (
                        <CloudUploadIcon />
                      ) : (
                        <InfoOutlinedIcon />
                      )
                    }
                    {...(this.state.AuthLetterByPOrg.length !== 0
                      ? {
                          endIcon: (
                            <DeleteIcon
                              onClick={() => {
                                this.setState({
                                  AuthLetterByPOrg: [],
                                });
                              }}
                              style={{ color: "red", zIndex: 9 }}
                            />
                          ),
                        }
                      : {})}
                  >
                    <Typography
                      variant="subtitle2"
                      style={{
                        fontSize: "12px",
                      }}
                    >
                      Browse or drag & drop file here (PDF, DOCX)
                    </Typography>
                  </Button>
                </label>
              ) : (
                <Button
                  className=""
                  fullWidth
                  id="PdfFile"
                  component="span"
                  variant="outlined"
                  style={{
                    padding: "15px",
                  }}
                  startIcon={
                    this.state.AuthLetterByPOrg.length === 0 ? (
                      <CloudUploadIcon />
                    ) : (
                      <InfoOutlinedIcon />
                    )
                  }
                  {...(this.state.AuthLetterByPOrg.length !== 0
                    ? {
                        endIcon: (
                          <DeleteIcon
                            onClick={() => {
                              this.setState({
                                BusinessRegistrationDocument: [],
                              });
                            }}
                            style={{ color: "red", zIndex: 9 }}
                          />
                        ),
                      }
                    : {})}
                >
                  <Typography
                    variant="subtitle2"
                    style={{
                      fontSize: "12px",
                    }}
                    noWrap
                  >
                    {this.state.AuthLetterFileName}
                  </Typography>
                </Button>
              )}
              <input
                className="textbox"
                name="pdf"
                id="pdf"
                multiple
                disabled={this.state.BusinessRegistrationDocument.length !== 0}
                accept="application/pdf"
                type="file"
                style={{ display: "none" }}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  this.UploadFile3(e);
                }}
                value={formik.values.OrganizationName}
              />
            </div>
          </InputContainer>
        </Grid>
      </Grid>
    );
    const Block3 = (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <div className="title">Admin Details</div>
        </Grid>
        <Grid item xs={6}>
          <InputContainer>
            <div className="setDisplay">
              <CustmizedInputLabel>FULL NAME</CustmizedInputLabel>
            </div>
            <div className="Custmizedinputs">
              <input
                className="textbox"
                name="AdminName"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.AdminName}
              />
            </div>
          </InputContainer>
        </Grid>
        <Grid item xs={6}>
          <InputContainer>
            <div className="setDisplay">
              <CustmizedInputLabel>Contact Number</CustmizedInputLabel>
            </div>
            <div className="Custmizedinputs">
              <PhoneInput
                specialLabel={""}
                country={"us"}
                onChange={formik.handleChange}
                value={formik.values.AdminNo}
                containerStyle={styles.container}
                buttonStyle={styles.button}
                inputStyle={styles.input}
              />
            </div>
          </InputContainer>
        </Grid>
        <Grid item xs={6}>
          <InputContainer>
            <div className="setDisplay">
              <CustmizedInputLabel>Designation</CustmizedInputLabel>
            </div>
            <div className="Custmizedinputs">
              <input
                className="textbox"
                name="AdminDesignation"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.AdminDesignation}
              />
            </div>
          </InputContainer>
        </Grid>
        <Grid item xs={6}>
          <InputContainer>
            <div className="setDisplay">
              <CustmizedInputLabel>Admin Department</CustmizedInputLabel>
            </div>
            <div className="Custmizedinputs">
              <input
                className="textbox"
                name="AdminDepartment"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.AdminDepartment}
              />
            </div>
          </InputContainer>
        </Grid>
        <Grid item xs={6}>
          <InputContainer>
            <div className="setDisplay">
              <CustmizedInputLabel>Email Address</CustmizedInputLabel>
            </div>
            <div className="Custmizedinputs">
              <input
                className="textbox"
                name="AdminEmail"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.AdminEmail}
              />
            </div>
          </InputContainer>
        </Grid>
        <Grid item xs={6}>
          <InputContainer>
            <div className="setDisplay">
              <CustmizedInputLabel>PASSWORD</CustmizedInputLabel>
              <InfoOutlinedIcon className="infoIcon" />
            </div>
            <div className="Custmizedinputs">
              <CustmizedPassword
                disableUnderline
                id="standard-adornment-password"
                type={this.state.showPassword ? "text" : "password"}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={this.handleClickShowPassword}
                      onChange={(e) => console.log(e)}
                      value={formik.values.phone}
                    >
                      {this.state.showPassword ? (
                        <VisibilityOff />
                      ) : (
                        <Visibility />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </div>
          </InputContainer>
        </Grid>
      </Grid>
    );
    const Block4 = (
      <Grid container>
        <Grid item xs={12}>
          <div className="title">Review & Submit</div>
        </Grid>
        <Grid item xs={12}>
          <ReviewBox>
            <ButtonContainer>
              <h4>Organisation Details</h4>
              <EditIcon />
            </ButtonContainer>
            <Grid container spacing={1}>
              <Grid item xs={5}>
                FULL NAME
              </Grid>
              <Grid item xs={7}>
                AP Moller - Maersk Line Shipping Company
              </Grid>
              <Grid item xs={5}>
                ORGANISATION'S UEN
              </Grid>
              <Grid item xs={7}>
                SFWF1325DGG
              </Grid>
              <Grid item xs={5}>
                BUSINESS REGISTRATION DOCUMENT
              </Grid>
              <Grid item xs={7}>
                business_reg_doc.pdf
              </Grid>
            </Grid>
          </ReviewBox>
          <ReviewBox>
            <ButtonContainer>
              <h4>Principal Organisation Details</h4>
              <EditIcon />
            </ButtonContainer>
            <Grid container spacing={1}>
              <Grid item xs={5}>
                PRINCIPAL ORGANISATION
              </Grid>
              <Grid item xs={7}>
                Orient Overseas Container Line Limited
              </Grid>
              <Grid item xs={5}>
              BUSINESS REGISTRATION DOCUMENT
              </Grid>
              <Grid item xs={7}>
                business_reg_doc.pdf
              </Grid>
              <Grid item xs={5}>
                AUTHORISATION LETTER
              </Grid>
              <Grid item xs={7}>
                business_reg_doc.pdf
              </Grid>
            </Grid>
          </ReviewBox>
          <ReviewBox>
            <ButtonContainer>
              <h4>Admin Details</h4>
              <EditIcon />
            </ButtonContainer>
            <Grid container spacing={1}>
              <Grid item xs={5}>
                FULL NAME
              </Grid>
              <Grid item xs={7}>
                Tom Chandler
              </Grid>
              <Grid item xs={5}>
                CONTACT NUMBER
              </Grid>
              <Grid item xs={7}>
                (+65) 1234567890
              </Grid>
              <Grid item xs={5}>
                DESIGNATION
              </Grid>
              <Grid item xs={7}>
                Manager
              </Grid>
              <Grid item xs={5}>
                DEPARTMENT
              </Grid>
              <Grid item xs={7}>
                Cargo
              </Grid>
              <Grid item xs={5}>
                EMAIL ADDRESS
              </Grid>
              <Grid item xs={7}>
                tomchandler@maersk.com
              </Grid>
              <Grid item xs={5}>
                PASSWORD
              </Grid>
              <Grid item xs={7}>
                egEG46E&GT3TR
              </Grid>
            </Grid>
          </ReviewBox>
        </Grid>
      </Grid>
    );

    if (step === 0) {
      return Block1;
    }
    if (step === 1) {
      return Block2;
    }
    if (step === 2) {
      return Block3;
    }
    if (step === 3) {
      return Block4;
    }
  };
  render() {
    return (
      // Required for all blocks
      <OrganizationPageStyle data-test-id="OrganizationSignUp">
        <AppBackground>
          <GenericCard>
            <div>
              <Stepper activeStep={this.state.activeStep} alternativeLabel>
                {this.state.stepLabels.map((label) => (
                  <Step key={label}>
                    <StepLabel>{label}</StepLabel>
                  </Step>
                ))}
              </Stepper>
              <div>
                <div>
                  <Formik
                    initialValues={{
                      OrganizationName: "",
                      OrganizationUEN: "",
                      BusinessRegistrationDoc: "",
                      PeincipalOrganization: "",
                      PrincipalRegistrationDoc: "",
                      AuthLetter: "",
                      AdminName: "",
                      AdminNo: "",
                      AdminDepartment: "",
                      AdminDesignation: "",
                      AdminEmail: "",
                      AdminPassword: "",
                    }}
                    onSubmit={(values) => {
                      console.log(values);
                      alert(JSON.stringify(values, null, 2));
                    }}
                  >
                    {(formik) => (
                      <form onSubmit={formik.handleSubmit}>
                        {this.renderBlock(this.state.activeStep, formik)}
                        <ButtonContainer>
                          <Button
                            disabled={this.state.activeStep === 0}
                            onClick={this.handleBack}
                            data-test-id="back"
                          >
                            Back
                          </Button>
                          {this.state.activeStep ===
                          this.state.stepLabels.length - 1 ? (
                            <Button
                              variant="contained"
                              color="primary"
                              type="submit"
                            >
                              Finish
                            </Button>
                          ) : (
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={this.handleNext}
                              data-test-id="next"
                            >
                              Next
                            </Button>
                          )}
                        </ButtonContainer>
                      </form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </GenericCard>
        </AppBackground>
      </OrganizationPageStyle>
    );
  }
}

const styles = {
  container: {
    width: "100%",
    height: "56px",
    borderRadius: "8px",
    border: "1px solid gray",
  },
  button: {
    border: "none",
    borderRadius: "9px",
  },
  input: {
    height: "56px",
    width: "100%",
    border: "none",
    borderRadius: "9px",
  },
};

const ButtonContainer = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  alignItems:"center"
});
const InputContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
});
const CustmizedInputLabel = styled(InputLabel)({
  fontSize: "14px",
  lineHeight: "16px",
  letterSpacing: "0em",
  textAlign: "left",
  fontFamily: "Asap",
  display: "contents",
  color: "black !important",
  textTransform: "uppercase",
});
const CustmizedPassword = styled(Input)({
  width: "100%",
  height: "56px",
  padding: "10px 22px 10px 12px",
  borderRadius: "8px",
  border: "1px solid gray",
});
const ReviewBox = styled(Box)({
  height: "auto",
  borderRadius: "16px",
  border: "1px",
  backgroundColor: "#CDCDCD",
  padding: "1rem",
  marginBottom:"1rem"
});
const OrganizationPageStyle = styled("div")({
  "& .title": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontWeight: "bold",
    fontSize: "1.3rem",
    margin: "1rem 0 2rem 0",
    fontFamily: "Asap",
  },
  "& .tooltip": {
    backgroundColor: "white !important",
    color: "rgba(0, 0, 0, 0.87)",
    fontSize: 11,
  },
  "& .setDisplay": {
    display: "flex",
  },
  "& .Custmizedinputs": {
    width: "100%",
    height: "56px",
    padding: "10px 22px 10px 0px",
    borderRadius: "8px",
    backgroundcolor: "red",
    borderRadious: "8px",
  },
  "& .textbox": {
    width: "100%",
    height: "56px",
    padding: "10px 22px 10px 12px",
    borderRadius: "8px",
    border: "1px solid gray",
  },
  "& .infoIcon": {
    fontSize: "1.2rem",
    marginLeft: "0.6rem",
  },
});
// Customizable Area End
