// Customizable Area Start
import * as React from "react";
const AlphaLogo = require("./image_logo.png");
const backgroundImg = require("./image_bg.png");
import { Box, Grid, Button } from "@material-ui/core";
import { styled } from "@material-ui/styles";

interface PermanentDrawerLeftProps {
  children?: React.ReactNode;
  loader?: boolean;
}

export default class AppBackground extends React.Component {
  constructor(props: PermanentDrawerLeftProps) {
    super(props);
  }
  render() {
    return (
      <BackgroundStyle>
        <Background>
          <img src={AlphaLogo} alt="logo" className="logo" />
          {this.props.children}
        </Background>
      </BackgroundStyle>
    );
  }
}
const Background = styled(Box)({
  height: "100vh !important",
  backgroundImage: `url(${backgroundImg})`,
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center", // Center the background image
  width: "100%",
  objectFit: "cover",
  position: "relative",
});

const BackgroundStyle = styled("div")({
  "& .logo": {
    position: "absolute",
    top: "30px",
    left: "30px",
  },
});

// Customizable Area End
